import { Vue, Prop, Component } from 'vue-property-decorator';
import { Action, Getter, State as ClassState } from 'vuex-class';
import { State } from '@/store/models';
import { Asset } from '@/store/models/asset';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import FundDocs from '@/components/common/fund-docs/FundDocs.vue';
import NetValue from '@/components/common/net-value/netValue.vue';
import PropertyReservationModal from '@/components/properties/grid-list/grid/reservation/PropertyReservationModal.vue';
import { Valuation } from '@/store/models/valuation';

@Component({
  components: {
    WithDownloads,
    FundDocs,
    NetValue,
    PropertyReservationModal,
  },
})

export default class PropertyInfo extends Vue {
  @Prop({ default: (): null => null }) asset!: Asset;
  @Prop({ default: (): boolean => false }) userLoggedIn!: boolean;
  @Action openModal!: Function;
  @ClassState user!: State['user'];
  @Getter isUserLoggedIn!: boolean;
  @Getter getActiveValuationByAsset!: Function;
  @Getter getAssetValuationSharePrice!: Function;

  get assetId(): string {
    return this.$route.params.id;
  }

  get assetAddress(): string {
    return this.asset ? this.asset.city : '';
  }

  get assetValuation(): Valuation | undefined {
    return this.asset && this.getActiveValuationByAsset(this.asset.id);
  }

  get assetSharePrice(): number | undefined {
    return this.asset && this.getAssetValuationSharePrice(this.asset.id);
  }
}
